import { useEffect, useState } from 'react'
import { fetchMembers, selectMembers, setCurrentPage, setFilters, setMembers } from '../../reducers/members'
import { useAppDispatch } from '../../reducers/store'
import DataGrid from '../data-grid/DataGrid'
import { memberColumns } from './MembersColumns'
import { useDispatch, useSelector } from 'react-redux'
import { Member, MemberConditionalAccessState, MemberSelectOption } from '../../types/MemberTypes'
import DropDown from '../dropdown/DropDown'
import { activatedOptions } from '../../strings/dropdown-options'
import Popout from '../popout/Popout'
import {
  Button,
  Grid,
  IconButton,
  Popover,
  TextField,
  Text,
  Box,
  Card,
  Strong,
  SegmentedControl,
} from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import AddSSOMembersModal from './AddSSOMembersModal'
import AddMembersModal from './AddMembersModal'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import AddCSVMembersModal from './AddCSVMembersModal'
import { useNavigate } from 'react-router-dom'
import { RoleType, SessionState } from '../../types/UserTypes'
import PageSkeleton from '../skeleton/PageSkeleton.tsx'
import { fetchConditionalAccessConfig } from '../../reducers/settings/settings.thunk.ts'
import { Pagination } from '../pagination/Pagination.tsx'
import { selectUser } from '../../reducers/users/users.slice.ts'
import { getRoles, permitsRole } from '../../utils/roles.ts'
import { IconFileAnalytics, IconLink, IconUser } from '@tabler/icons-react'

function MembersWrapper() {
  const { members, totalPages, currentPage, filters } = useSelector(selectMembers)
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [nameFilter, setNameFilter] = useState<string>('')
  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [popoutData, setPopoutData] = useState<Member | null>(null)
  const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false)
  const [showSSOModal, setShowSSOModal] = useState<boolean>(false)
  const [showCSVModal, setShowCSVModal] = useState<boolean>(false)
  const [openMemberOptions, setOpenMemberOptions] = useState<boolean>(false)
  const [goToGroups, setGoToGroups] = useState<boolean>(false)
  const [showConditionalAccess, setShowConditionalAccess] = useState<boolean>(false)
  const [policies, setPolicies] = useState<string>('all')
  const [ssoEnabledForGeneralAdmin, setSsoEnabledForGeneralAdmin] = useState<boolean>(false)

  const { session } = useSelector(selectUser)
  const roles = getRoles(session)

  useEffect(() => {
    di(fetchMembers(filters, currentPage))
    setNameFilter(filters.name)
    setPolicies(filters.policies)
  }, [filters])

  useEffect(() => {
    if (members || (members && (members as Member[]).length === 0)) {
      setDataLoading(false)
    } else if (!members) {
      setDataLoading(true)
    }
  }, [members])

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    const goToSSO = localStorage.getItem('SSO_ONBOARDING_COOKIE') as string
    const goToConfigured = localStorage.getItem('SSO_CONFIGURED_COOKIE') as string

    fetchConditionalAccess()

    if (goToConfigured === 'true') {
      setGoToGroups(false)
      setShowSSOModal(true)
    }
    if (session === '{}') {
      localStorage.removeItem('SSO_CONFIGURED_COOKIE')
      navigate('/login')
    }
    if (goToSSO === 'true') {
      setGoToGroups(true)
      setShowSSOModal(true)
    }

    const parsedSession = JSON.parse(session) as SessionState
    if (parsedSession?.organization.ssoProvider) {
      setGoToGroups(true)
      setSsoEnabledForGeneralAdmin(permitsRole(roles, [RoleType.GENERAL_ADMIN]))
    }
    return () => {
      dispatch(setMembers(undefined))
    }
  }, [])

  const fetchConditionalAccess = async () => {
    const conditionalAccess = await di(fetchConditionalAccessConfig())
    setShowConditionalAccess(conditionalAccess?.active ?? false)
  }

  const clearNameFilter = () => {
    setDataLoading(true)
    setNameFilter('')
    dispatch(setFilters({ ...filters, name: '' }))
  }

  const handlePageClick = (data: MemberSelectOption) => {
    setDataLoading(true)
    di(fetchMembers({ ...filters }, data.selected))
  }

  const handleNoMembersText = () => {
    switch (policies) {
      case MemberConditionalAccessState.ALL:
        return 'No members found. Refine your search criteria.'
      case MemberConditionalAccessState.COMPLIANT:
        return 'No members are currently compliant based on your Conditional Access Policies or filters.'
      case MemberConditionalAccessState.BLOCKED:
        return 'No members are currently suspended based on your Conditional Access Policies or filters.'
      case MemberConditionalAccessState.FAILED:
        return 'No members have failed to be suspended by your Conditional Access Policies or filters.'
      default:
        return 'No members found. Refine your search criteria.'
    }
  }

  const resetFilters = () => {
    dispatch(setCurrentPage(0))
    dispatch(setFilters({ name: '', activatedDevices: [], policies: '' }))
  }

  const updateNameFilter = () => {
    dispatch(setCurrentPage(0))
    dispatch(setFilters({ ...filters, name: nameFilter }))
  }

  function updateActivatedDevicesFilter(value: string): void {
    dispatch(setCurrentPage(0))
    dispatch(setFilters({ ...filters, activatedDevices: value }))
  }

  function updatePoliciesFilter(value: string): void {
    setPolicies(value)
    dispatch(setCurrentPage(0))
    dispatch(setFilters({ ...filters, policies: value }))
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] bg-neutral-3 p-9">
        {dataLoading && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        <div className="flex justify-between">
          <div className="mb-6 flex w-full">
            {showConditionalAccess && (
              <SegmentedControl.Root
                defaultValue={MemberConditionalAccessState.ALL}
                value={policies == '' ? MemberConditionalAccessState.ALL : policies}
                className="h-[38px] mr-4 bg-[#00003B0D] text-gray-600"
                onValueChange={updatePoliciesFilter}
                size={'2'}
              >
                <SegmentedControl.Item value={MemberConditionalAccessState.ALL}>All</SegmentedControl.Item>
                <SegmentedControl.Item value={MemberConditionalAccessState.COMPLIANT}>Compliant</SegmentedControl.Item>
                <SegmentedControl.Item value={MemberConditionalAccessState.BLOCKED}>Suspended</SegmentedControl.Item>
                <SegmentedControl.Item value={MemberConditionalAccessState.FAILED}>Failed</SegmentedControl.Item>
              </SegmentedControl.Root>
            )}
            <TextField.Root
              value={nameFilter}
              placeholder="Search..."
              className="w-[200px] h-[38px] mr-4 !pl-0"
              onChange={(e) => {
                setNameFilter(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  updateNameFilter()
                }
              }}
            >
              <TextField.Slot className="pl-0"></TextField.Slot>
              {filters.name !== '' && (
                <TextField.Slot>
                  <IconButton
                    size="1"
                    variant="ghost"
                    onClick={() => {
                      clearNameFilter()
                    }}
                  >
                    <CrossCircledIcon height="14" width="14" />
                  </IconButton>
                </TextField.Slot>
              )}
              <TextField.Slot>
                <IconButton size="1" variant="ghost" onClick={updateNameFilter}>
                  <MagnifyingGlassIcon height="14" width="14" />
                </IconButton>
              </TextField.Slot>
            </TextField.Root>
            <DropDown
              value={filters?.activatedDevices}
              onChangeHandler={(value) => updateActivatedDevicesFilter(value)}
              options={activatedOptions}
              style="z-10 mr-4 min-w-[180px]"
              placeholder="Activated Devices"
              multiple={true}
              disabled={false}
            />
          </div>
          <div className="flex ml-auto justify-end">
            <Popover.Root open={openMemberOptions} onOpenChange={setOpenMemberOptions}>
              <Popover.Trigger>
                <Button className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer">Add Members</Button>
              </Popover.Trigger>
              <Popover.Content
                width={
                  permitsRole(roles, [RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT]) || ssoEnabledForGeneralAdmin
                    ? '600px'
                    : '360px'
                }
                className="flex justify-content"
              >
                <Grid
                  columns={
                    permitsRole(roles, [RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT]) || ssoEnabledForGeneralAdmin
                      ? '3'
                      : '2'
                  }
                  gapX={'10px'}
                >
                  {roles &&
                    (permitsRole(roles, [RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT]) ||
                      ssoEnabledForGeneralAdmin) && (
                      <Box
                        maxWidth="160px"
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenMemberOptions(false)
                          setShowSSOModal(true)
                        }}
                      >
                        <Card size="2">
                          <div
                            className="h-8 w-8 rounded flex justify-center items-center"
                            style={{ backgroundColor: '#F2F0FF' }}
                          >
                            <IconLink size={20} color="#6128D1" />
                          </div>
                          <Text as="p" size="1" className="mt-5">
                            <Strong>SSO</Strong>
                            <br /> Connect an existing SSO provider for import.
                          </Text>
                        </Card>
                      </Box>
                    )}
                  <Box
                    maxWidth="160px"
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenMemberOptions(false)
                      setShowCSVModal(true)
                    }}
                  >
                    <Card size="2">
                      <div
                        className="h-8 w-8 rounded flex justify-center items-center"
                        style={{ backgroundColor: '#F2F0FF' }}
                      >
                        <IconFileAnalytics size={20} color="#6128D1" />
                      </div>
                      <Text as="p" size="1" className="mt-5">
                        <Strong>CSV</Strong>
                        <br /> Upload a populated CSV file for bulk import.
                      </Text>
                    </Card>
                  </Box>
                  <Box
                    maxWidth="160px"
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenMemberOptions(false)
                      setShowAddMemberModal(true)
                    }}
                  >
                    <Card size="2">
                      <div
                        className="h-8 w-8 rounded flex justify-center items-center"
                        style={{ backgroundColor: '#F2F0FF' }}
                      >
                        <IconUser size={20} color="#6128D1" />
                      </div>
                      <Text as="p" size="1" className="mt-5">
                        <Strong>Manual</Strong>
                        <br /> Enter members manually using their emails.
                      </Text>
                    </Card>
                  </Box>
                </Grid>
              </Popover.Content>
            </Popover.Root>
          </div>
        </div>

        {members && members.length > 0 && (
          <>
            <DataGrid
              columns={memberColumns(setShowPopout, setPopoutData, showConditionalAccess)}
              data={members}
              loading={dataLoading}
            />
            <div className="mt-2">
              <Pagination totalPages={totalPages} forcePage={currentPage} handlePageClick={handlePageClick} />
            </div>
          </>
        )}
        {members && members.length === 0 && (
          <div className="block items-center mt-20">
            <div className="flex items-center justify-center">
              <MagnifyingGlassIcon className="h-12 w-12" />
            </div>
            <p className="text-center font-bold">No Results Found</p>
            <p className="text-center text-sm flex mx-auto max-w-[300px]">{handleNoMembersText()}</p>
            <Button
              className="mt-4 text-center flex bg-black mx-auto"
              onClick={() => {
                resetFilters()
              }}
            >
              Clear all filters
            </Button>
          </div>
        )}
      </div>
      {showPopout && <Popout setShowPopout={setShowPopout} contentScreen="MEMBERS" popoutData={popoutData} />}
      {showAddMemberModal && <AddMembersModal setShowModal={setShowAddMemberModal} />}
      {showSSOModal && <AddSSOMembersModal setShowModal={setShowSSOModal} goToGroups={goToGroups} />}
      {showCSVModal && <AddCSVMembersModal setShowCSVModal={setShowCSVModal} />}
    </>
  )
}

export default MembersWrapper
