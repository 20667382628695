import { SelectOption } from './DropDownTypes'

export interface Admins {
  admins: Admin[]
}

export interface Admin {
  name: string
  email: string
  roles: SelectOption[]
  inviteAccepted?: boolean
  inviteSentDate?: string
}

export interface AddThreatHunterUser {
  name: string
  email: string
}

export interface AdminResponse {
  admins: {
    name: string
    email: string
    roles: string
    inviteAccepted?: boolean
    inviteSentDate?: string
  }[]
}

export interface AuditLogs {
  data: AuditLog[]
  totalPages: number
}

export interface AuditLog {
  createdAt: string
  createdBy: string
  action: string
  actionDetails: string
  metadata: string
}

export interface AuditLogFilter {
  fromDateTime: string
  toDateTime: string
}

export interface BillingInfo {
  card: Card
  subscription: Subscription
}

export interface Card {
  lastFour: string
  network: string
}

export interface Subscription {
  status: string
  invoice: boolean
  amountDue: number
  renewDate: number
  cancelDate: string
}

export interface Plan {
  status: string
  statusMessage: string
  relevantDate: string
}

export type AddSsoMemberInputs = {
  clientId: string
  clientSecret: string
  tenant: string
}

export type AddSsoSettings = {
  clientId: string
  clientSecret: string
  tenant: string
  provider: string
  slug: string
}

export type SsoGroups = {
  nextPage: boolean
  groups: Group[]
}

export type Group = {
  id: string
  name: string
}

export type MemberGroup = {
  id: string
  name: string
}

export type MembersResponse = {
  memberCount: number
  members: SsoMember[]
  error?: string
}

export type SsoMember = {
  id: string
  name: string
  email: string
  createdAt: string
  devices: string
  duplicate?: boolean
}

export type CreateSsoMembersPayload = {
  ssoGroups: Group[]
  allowedDuplicates: AllowedDuplicates[]
}

export type AllowedDuplicates = {
  id: string
  name: string
  email: string
}

export interface ApiKey {
  token: string
  expiresAt: string
}

export interface WebhookSecret {
  secret: string
  webhookType: WebhookType
}

export interface CreateSSOMemberResponse {
  numMembersCreated: number
  error?: string
}

export interface WebhookPayload {
  secret: string
  url: string
  webhookType: WebhookType
}

export enum WebhookType {
  GENERAL = 'GENERAL',
  ALERT = 'ALERT',
  AUDIT_LOG = 'AUDIT_LOG',
}

export interface ConditionalAccessResponse {
  tenant: string
  clientId: string
  clientSecret: string
  userExtension: string
  groupId: string
  provider: string
  active: boolean
}

export interface ConditionalAccessPolicyResponse {
  alertSeverity: string
  deviceIntegrityPeriod: string
  outdatedOSPeriod: string
  screenLockEnabled: string
  smishingEnabled: string
  biometricsEnabled: string
}

export interface WebhookResponse {
  success: boolean
  message: string
}

export interface Scim extends ScimEnabled {
  scimUrl: string
  authHeader: string
}

export interface ScimEnabled {
  enabled: boolean
}

export interface Mdm {
  enabled: boolean
  token: string
}

export interface EmailPreferences {
  weeklySummary: boolean
  threatAlerts: boolean
  enrollmentEmailsFrequency: string
  outdatedOsEmailsFrequency: string
}

export interface NotificationPreferences {
  notificationType: string
  topic: string
  deviceIntegrityPeriod: string
  notificationFrequency: string
}

export interface EnrollEmailTemplate {
  type: string
  subject: string
  body: string
}

export type EnrollEmailTemplateInputs = {
  subject: string
  body: string
}

export type ThreatHunterUsersResponse = {
  threatHunterUsers: ThreatHunterUser[]
  error?: string
}

export interface ThreatHunterUser {
  id: number
  name: string
  email: string
  accessCode: string
  identifier: string
  status: string
  scannedBy?: string
}
