import { MemberFilters, setCurrentPage, setMembers, setTotalPages } from '.'
import Server from '../../api/server'
import {
  ADD_MEMBERS_ROUTE,
  MEMBERS_ROUTE,
  REMOVE_MEMBER_ROUTE,
  REMOVE_GRACE_PERIOD_ROUTE,
  UNBLOCK_MEMBERS_ROUTE,
} from '../../strings/api-consts'
import {
  AddMemberResponse,
  GracePeriod,
  MemberConditionalAccessState,
  MemberPayload,
  Members,
} from '../../types/MemberTypes'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

const server = new Server()

export const fetchMembers =
  (filters: MemberFilters, page: number = 0) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = MEMBERS_ROUTE + `?page=${page ? page + 1 : 1}&pageSize=${40}`
    if (filters?.activatedDevices) {
      if (filters?.activatedDevices.length === 2 || filters?.activatedDevices.length === 0) {
        url += ''
      } else if (filters?.activatedDevices.length === 1 && filters?.activatedDevices[0].value === 'no') {
        url += '&enrolled=false'
      } else {
        url += '&enrolled=true'
      }
    }

    if (filters?.name && filters?.name !== '') {
      const encodedNameFilter = encodeURIComponent(filters?.name)
      url += `&name=${encodedNameFilter}`
    }

    if (filters?.policies && filters?.policies !== MemberConditionalAccessState.ALL) {
      url += `&policy=${filters?.policies}`
    }

    return server
      .get(url, dispatch)
      .then((members: Members) => {
        dispatch(setCurrentPage(page))
        dispatch(setTotalPages(members.totalPages))
        dispatch(setMembers(members.members))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
        return false
      })
  }

export const addMembers = (membersPayload: MemberPayload[]) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(ADD_MEMBERS_ROUTE, membersPayload, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((response: AddMemberResponse) => {
      return response
    })
    .catch((err) => {
      return err
    })
}

export const postUnblockMember =
  (memberId: string, memberBlockedByPolicy: string, gracePeriod: string) => async (dispatch: CustomThunkDispatch) => {
    const now = new Date()
    let unblockTime: Date

    switch (gracePeriod) {
      case GracePeriod.ONE_HOUR:
        unblockTime = new Date(now.getTime() + 1 * 60 * 60 * 1000) // 1 hour
        break
      case GracePeriod.FOUR_HOURS:
        unblockTime = new Date(now.getTime() + 4 * 60 * 60 * 1000) // 4 hours
        break
      case GracePeriod.ONE_DAY:
        unblockTime = new Date(now.getTime() + 24 * 60 * 60 * 1000) // 1 day
        break
      case GracePeriod.THREE_DAYS:
        unblockTime = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000) // 3 days
        break
      case GracePeriod.ONE_WEEK:
        unblockTime = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000) // 1 week
        break
      default:
        unblockTime = now
    }

    return server
      .post(
        UNBLOCK_MEMBERS_ROUTE,
        {
          memberId: memberId,
          policy: memberBlockedByPolicy,
          gracePeriod: unblockTime.toISOString(),
        },
        dispatch
      )
      .then((res) => {
        {
          if (!res) {
            throw new Error('Response is undefined')
          }
          if (res.status === 500) {
            throw new Error('Server error')
          }
          return true
        }
      })
      .catch((error) => {
        Sentry.captureMessage(error.message)
        return undefined
      })
  }

export const postRemoveMemberGracePeriod = (memberId: string) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(REMOVE_GRACE_PERIOD_ROUTE, { memberId: memberId }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      if (res.status === 500) {
        throw new Error('Server error')
      }
      return true
    })
    .catch((error) => {
      Sentry.captureMessage(error.message)
      return undefined
    })
}

export const postRemoveMember = (memberId: string) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(REMOVE_MEMBER_ROUTE, { memberId: memberId }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      if (res.status === 500) {
        throw new Error('Server error')
      }
      return true
    })
    .catch((error) => {
      Sentry.captureMessage(error.message)
      return undefined
    })
}
