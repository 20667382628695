import { Button } from '@radix-ui/themes'
import NavMenu from '../nav-menu/NavMenu'
import { logoutUser, selectUser } from '../../reducers/users'
import { useAppDispatch } from '../../reducers/store'
import { useNavigate } from 'react-router-dom'
import { IconHelp, IconLogout, IconNotebook, IconSettings } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function SideBar() {
  const di = useAppDispatch()
  const navigate = useNavigate()

  const logout = async () => {
    await di(logoutUser())
    localStorage.removeItem('DASHBOARD_PAGE_COOKIE')
    navigate('/login')
  }
  const { session } = useSelector(selectUser)
  const roles = getRoles(session)

  return (
    <>
      <div className="w-[182px] flex-grow mx-4 h-[calc(100vh_-_74px)]">
        {/* NAV MENU */}
        <NavMenu />

        {/* BOTTOM NAV */}
        <div className="w-[182px] absolute bottom-0 mb-5">
          <Button
            variant="solid"
            size="3"
            className="text-black bg-white w-[182px] h-10 justify-start cursor-pointer mb-1"
            onClick={() => window.open('https://iverifyio.atlassian.net/servicedesk/customer/portal/1', '_blank')}
            style={{
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            <IconHelp size="18" /> Support
          </Button>
          <Button
            variant="solid"
            size="3"
            className="text-black bg-white w-[182px] h-10 justify-start cursor-pointer mb-1"
            onClick={() => window.open('https://docs.iverify.io/iverify-release-notes', '_blank')}
            style={{
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            <IconNotebook size="18" /> Release Notes
          </Button>
          {roles && permitsRole(roles, [RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT]) && (
            <Button
              variant="solid"
              size="3"
              className="text-black bg-white w-[182px] h-10 justify-start cursor-pointer mb-1"
              onClick={() => navigate('/settings')}
              style={{
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              <IconSettings size="18" /> Settings
            </Button>
          )}
          <Button
            variant="solid"
            size="3"
            className="text-black bg-white w-[182px] h-10 justify-start cursor-pointer"
            onClick={() => logout()}
            style={{
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            <IconLogout size="18" /> Sign Out
          </Button>
        </div>
      </div>
    </>
  )
}

export default SideBar
