import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useAppDispatch } from '../../reducers/store'
import { resetPassword, setLoginPage } from '../../reducers/users'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { forgotSchema } from '../../schemas/LoginSchemas'
import { Button } from '@radix-ui/themes'
import { Input } from '../input/Input'

type resetInputs = {
  email: string
}

function ResetPasswordForm() {
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false)
  const dispatch = useDispatch()
  const di = useAppDispatch()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<resetInputs>({ resolver: yupResolver(forgotSchema) })
  const onSubmit: SubmitHandler<resetInputs> = async (data) => {
    await di(resetPassword(data.email))
    setShowUserInfo(true)
  }

  return (
    <div className="block w-[80%] lg:w-[70%] 2xl:w-[60%] max-w-[460px]">
      <p className="text-black text-3xl text-left mb-6">Forgotten Password</p>
      {!showUserInfo && (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="block mt-4">
            <label className="text-black w-full">Email Address</label>
            <div className="mt-2">
              <Input
                autoFocus={true}
                type="email"
                placeholder="name@example.com"
                {...register('email', { required: true })}
                error={!!errors.email}
                onChange={undefined}
              />
            </div>
            {errors.email && <p className="text-ErrorRed">{errors.email.message}</p>}
            <Button
              type="submit"
              className="w-full mt-9 cursor-pointer bg-PurpleBlue-9 hover:bg-PurpleBlue-10 active:bg-PurpleBlue-10"
            >
              Send
            </Button>
          </form>
        </>
      )}
      {showUserInfo && (
        <p className="mt-5 text-left">
          Password reset instructions have been sent to <span>{getValues('email')}</span>
        </p>
      )}
      <Button
        onClick={() => dispatch(setLoginPage('LOGIN'))}
        className="text-center mt-6 cursor-pointer w-full ml-0 w-[96%]"
        variant="ghost"
      >
        Back to Sign In
      </Button>
    </div>
  )
}

export default ResetPasswordForm
