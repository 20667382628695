import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import {
  deactivateWebhook,
  fetchWebhook,
  fetchWebhookSecrets,
  saveWebhook,
  selectSettings,
} from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { Button, Card, Flex, IconButton, Separator, Switch, Text } from '@radix-ui/themes'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import IntegrationModal from './IntegrationModal'
import toast from 'react-hot-toast'
import { WebhookPayload, WebhookResponse, WebhookType } from '../../types/SettingsType'
import { Input } from '../input/Input'
import { selectUser } from '../../reducers/users'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function WebhookSettings() {
  const { webhooks, webhookSecrets } = useSelector(selectSettings)

  const [showGeneralWebhook, setShowGeneralWebhook] = useState<boolean>(false)
  const [showAlertWebhook, setShowAlertWebhook] = useState<boolean>(false)
  const [showAuditLogWebhook, setShowAuditLogWebhook] = useState<boolean>(false)

  const [generalWebhookURL, setGeneralWebhookURL] = useState<string>('')
  const [alertWebhookURL, setAlertWebhookURL] = useState<string>('')
  const [auditLogWebhookURL, setAuditLogWebhookURL] = useState<string>('')

  const [generalWebhookSecret, setGeneralWebhookSecret] = useState<string>('')
  const [alertWebhookSecret, setAlertWebhookSecret] = useState<string>('')
  const [auditLogWebhookSecret, setAuditLogWebhookSecret] = useState<string>('')

  const [showGeneralWebhookKey, setShowGeneralWebhookKey] = useState<boolean>(false)
  const [showAlertWebhookKey, setShowAlertWebhookKey] = useState<boolean>(false)
  const [showAuditLogWebhookKey, setShowAuditLogWebhookKey] = useState<boolean>(false)

  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false)
  const [webhookTypeToRemove, setWebhookTypeToRemove] = useState<WebhookType>(WebhookType.GENERAL)
  const [screenToShow, setScreenToShow] = useState<string>('REVOKE')
  const [webhookValidating, setWebhookValidating] = useState<boolean>(false)
  const di = useAppDispatch()
  const { session } = useSelector(selectUser)

  const roles = getRoles(session)
  const rolePermitsWebhookPolicy = permitsRole(roles, [RoleType.PLATFORM_MGMT])

  useEffect(() => {
    if (rolePermitsWebhookPolicy) {
      fetchWebhookData()
    }
    if (roles && !rolePermitsWebhookPolicy) {
      window.location.href = '/forbidden'
    }
  }, [session])

  const saveWebhookDetails = async (saveRequest: WebhookPayload) => {
    setWebhookValidating(true)
    const response = (await di(saveWebhook(saveRequest))) as WebhookResponse
    if (!response.success) {
      toast.error(response.message, { duration: 6000 })
    } else {
      toast.success('Webhook successfully saved', { duration: 6000 })
    }
    setWebhookValidating(false)
  }

  const fetchWebhookData = async () => {
    const webhooks = await di(fetchWebhook())
    if (webhooks?.length !== 3) {
      fetchMissingWebhookSecrets(webhooks ?? [])
    }
    webhooks?.map((webhook) => {
      if (webhook.webhookType === WebhookType.GENERAL) {
        setShowGeneralWebhook(true)
        setGeneralWebhookURL(webhook.url)
        setGeneralWebhookSecret(webhook.secret)
      } else if (webhook.webhookType === WebhookType.ALERT) {
        setShowAlertWebhook(true)
        setAlertWebhookURL(webhook.url)
        setAlertWebhookSecret(webhook.secret)
      } else if (webhook.webhookType === WebhookType.AUDIT_LOG) {
        setShowAuditLogWebhook(true)
        setAuditLogWebhookURL(webhook.url)
        setAuditLogWebhookSecret(webhook.secret)
      }
    })
  }

  const fetchMissingWebhookSecrets = async (webhooks: WebhookPayload[]) => {
    const existingWebhookTypes = webhooks.map((webhook) => webhook.webhookType)
    const missingWebhookTypes = Object.values(WebhookType).filter((type) => !existingWebhookTypes.includes(type))

    await di(fetchWebhookSecrets(missingWebhookTypes))
  }

  const toggleWebhook = (e: boolean, type: WebhookType) => {
    if (!e && webhooks) {
      if (type === WebhookType.GENERAL) {
        setShowGeneralWebhook(!showGeneralWebhook)
        setWebhookTypeToRemove(WebhookType.GENERAL)
      } else if (type === WebhookType.ALERT) {
        setShowAlertWebhook(!showAlertWebhook)
        setWebhookTypeToRemove(WebhookType.ALERT)
      } else if (type === WebhookType.AUDIT_LOG) {
        setShowAuditLogWebhook(!showAuditLogWebhook)
        setWebhookTypeToRemove(WebhookType.AUDIT_LOG)
      }
      setScreenToShow('WEBHOOK')
      setShowRevokeModal(true)
    } else {
      if (type === WebhookType.GENERAL) {
        setShowGeneralWebhook(!showGeneralWebhook)
      } else if (type === WebhookType.ALERT) {
        setShowAlertWebhook(!showAlertWebhook)
      } else if (type === WebhookType.AUDIT_LOG) {
        setShowAuditLogWebhook(!showAuditLogWebhook)
      }
    }
  }

  const callDeactivateWebhook = async (type: WebhookType) => {
    await di(deactivateWebhook(type))
    if (type === WebhookType.GENERAL) {
      setGeneralWebhookURL('')
    } else if (type === WebhookType.ALERT) {
      setAlertWebhookURL('')
    } else if (type === WebhookType.AUDIT_LOG) {
      setAlertWebhookURL('')
    }
    toast.success('Webhook successfully deactivated', { duration: 6000 })
  }

  const cancelDeactivateWebhook = () => {
    if (webhookTypeToRemove === WebhookType.GENERAL) {
      setShowGeneralWebhook(!showGeneralWebhook)
    } else if (webhookTypeToRemove === WebhookType.ALERT) {
      setShowAlertWebhook(!showAlertWebhook)
    } else if (webhookTypeToRemove === WebhookType.AUDIT_LOG) {
      setShowAuditLogWebhook(!showAuditLogWebhook)
    }
  }

  const alertWebhookSecretFromSecrets = (type: WebhookType): string => {
    return webhookSecrets?.find((webhook) => webhook.webhookType === type)?.secret || ''
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto">
        {/* Webhook settings */}
        {rolePermitsWebhookPolicy && (
          <Flex direction="column" gap="3" maxWidth="100%">
            <Card variant="surface">
              <Flex justify="between">
                <Text as="div" size="2" weight="bold">
                  General Webhook
                </Text>
                <Switch
                  size="2"
                  checked={showGeneralWebhook}
                  onCheckedChange={(e) => toggleWebhook(e, WebhookType.GENERAL)}
                />
              </Flex>
              {showGeneralWebhook && (
                <>
                  <Separator my="3" size="4" />
                  <div className="flex mt-2 justify-between">
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2" color="gray">
                        Webhook Secret
                      </Text>
                      <div className="h-[42px] flex mt-1 border-InputBorderGrey border rounded px-2 py-2.5 justify-between text-sm">
                        {showGeneralWebhookKey
                          ? generalWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.GENERAL)
                          : '*********************'}
                        <div className="flex mt-0.5">
                          <IconButton
                            size="2"
                            variant="ghost"
                            className="mr-1"
                            onClick={() => setShowGeneralWebhookKey(!showGeneralWebhookKey)}
                          >
                            {showGeneralWebhookKey ? (
                              <EyeNoneIcon height="16" width="16" />
                            ) : (
                              <EyeOpenIcon height="16" width="16" />
                            )}
                          </IconButton>
                          <IconButton
                            size="2"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(generalWebhookSecret)
                              toast.success('API Key copied to clipboard', { duration: 6000 })
                            }}
                          >
                            <CopyIcon height="16" width="16" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2 mb-1" color="gray">
                        Webhook URL
                      </Text>
                      <Input type="text" value={generalWebhookURL} onChange={setGeneralWebhookURL} />
                    </div>
                    <Button
                      variant="solid"
                      size="3"
                      className={`h-9 w-[200px] bg-PurpleBlue-10 text-white cursor-pointer text-sm font-semibold mt-7`}
                      onClick={() =>
                        saveWebhookDetails({
                          secret: generalWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.GENERAL),
                          url: generalWebhookURL,
                          webhookType: WebhookType.GENERAL,
                        })
                      }
                      disabled={webhookValidating}
                    >
                      {webhookValidating ? 'Validating...' : 'Save'}
                    </Button>
                  </div>
                </>
              )}
            </Card>
            <Card variant="surface">
              <Flex justify="between">
                <Text as="div" size="2" weight="bold">
                  Alerts Webhook
                </Text>
                <Switch
                  size="2"
                  checked={showAlertWebhook}
                  onCheckedChange={(e) => toggleWebhook(e, WebhookType.ALERT)}
                />
              </Flex>
              {showAlertWebhook && (
                <>
                  <Separator my="3" size="4" />
                  <div className="flex mt-2 justify-between">
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2" color="gray">
                        Webhook Secret
                      </Text>
                      <div className="h-[42px] flex mt-1 border-InputBorderGrey border rounded px-2 py-2.5 justify-between text-sm">
                        {showAlertWebhookKey
                          ? alertWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.ALERT)
                          : '*********************'}
                        <div className="flex mt-0.5">
                          <IconButton
                            size="2"
                            variant="ghost"
                            className="mr-1"
                            onClick={() => setShowAlertWebhookKey(!showAlertWebhookKey)}
                          >
                            {showAlertWebhookKey ? (
                              <EyeNoneIcon height="16" width="16" />
                            ) : (
                              <EyeOpenIcon height="16" width="16" />
                            )}
                          </IconButton>
                          <IconButton
                            size="2"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(alertWebhookSecret)
                              toast.success('API Key copied to clipboard', { duration: 6000 })
                            }}
                          >
                            <CopyIcon height="16" width="16" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2 mb-1" color="gray">
                        Webhook URL
                      </Text>
                      <Input type="text" value={alertWebhookURL} onChange={setAlertWebhookURL} />
                    </div>
                    <Button
                      variant="solid"
                      size="3"
                      className={`h-9 w-[200px] bg-PurpleBlue-10 text-white cursor-pointer text-sm font-semibold mt-7`}
                      onClick={() =>
                        saveWebhookDetails({
                          secret: alertWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.ALERT),
                          url: alertWebhookURL,
                          webhookType: WebhookType.ALERT,
                        })
                      }
                      disabled={webhookValidating}
                    >
                      {webhookValidating ? 'Validating...' : 'Save'}
                    </Button>
                  </div>
                </>
              )}
            </Card>
            <Card variant="surface">
              <Flex justify="between">
                <Text as="div" size="2" weight="bold">
                  Audit Log Webhook
                </Text>
                <Switch
                  size="2"
                  checked={showAuditLogWebhook}
                  onCheckedChange={(e) => toggleWebhook(e, WebhookType.AUDIT_LOG)}
                />
              </Flex>
              {showAuditLogWebhook && (
                <>
                  <Separator my="3" size="4" />
                  <div className="flex mt-2 justify-between">
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2" color="gray">
                        Webhook Secret
                      </Text>
                      <div className="h-[42px] flex mt-1 border-InputBorderGrey border rounded px-2 py-2.5 justify-between text-sm">
                        {showAuditLogWebhookKey
                          ? auditLogWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.AUDIT_LOG)
                          : '*********************'}
                        <div className="flex mt-0.5">
                          <IconButton
                            size="2"
                            variant="ghost"
                            className="mr-1"
                            onClick={() => setShowAuditLogWebhookKey(!showAuditLogWebhookKey)}
                          >
                            {showAuditLogWebhookKey ? (
                              <EyeNoneIcon height="16" width="16" />
                            ) : (
                              <EyeOpenIcon height="16" width="16" />
                            )}
                          </IconButton>
                          <IconButton
                            size="2"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(auditLogWebhookSecret)
                              toast.success('API Key copied to clipboard', { duration: 6000 })
                            }}
                          >
                            <CopyIcon height="16" width="16" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2 mb-1" color="gray">
                        Webhook URL
                      </Text>
                      <Input type="text" value={auditLogWebhookURL} onChange={setAuditLogWebhookURL} />
                    </div>
                    <Button
                      variant="solid"
                      size="3"
                      className={`h-9 w-[200px] bg-PurpleBlue-10 text-white cursor-pointer text-sm font-semibold mt-7`}
                      onClick={() =>
                        saveWebhookDetails({
                          secret: auditLogWebhookSecret || alertWebhookSecretFromSecrets(WebhookType.AUDIT_LOG),
                          url: auditLogWebhookURL,
                          webhookType: WebhookType.AUDIT_LOG,
                        })
                      }
                      disabled={webhookValidating}
                    >
                      {webhookValidating ? 'Validating...' : 'Save'}
                    </Button>
                  </div>
                </>
              )}
            </Card>
            <Text as="div" color="gray" size="2" className="mt-4">
              Read{' '}
              <span className="text-PurpleBlue-10 cursor-pointer">
                <a href="https://docs.iverify.io/iverify-webhooks" target="_blank">
                  Webhooks Setup Documentation
                </a>
              </span>
            </Text>
          </Flex>
        )}
      </div>
      {showRevokeModal && (
        <IntegrationModal
          setShowIntegrationModal={setShowRevokeModal}
          screenToShow={screenToShow}
          deactivateWebhook={callDeactivateWebhook}
          cancelDeactivateWebhook={cancelDeactivateWebhook}
          webhookType={webhookTypeToRemove}
        />
      )}
    </>
  )
}

export default WebhookSettings
