import { CopyIcon } from '@radix-ui/react-icons'
import { Box, Button, Card, IconButton } from '@radix-ui/themes'
import oktaLogo from '../../assets/okta-logo.png'
import azureLogo from '../../assets/azure-logo.png'
import googleLogo from '../../assets/google-logo.png'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AddSsoMemberInputs } from '../../types/SettingsType'
import { yupResolver } from '@hookform/resolvers/yup'
import { addSSOMemberSchema } from '../../schemas/SettingsSchema'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { applySsoSettings } from '../../reducers/settings'
import { useAppDispatch } from '../../reducers/store'
import { SessionState } from '../../types/UserTypes'
import { Input } from '../input/Input'

function SsoSettingsPage() {
  const [provider, setProvider] = useState<string>('')
  const session = JSON.parse(localStorage.getItem('session') as string) as SessionState
  const redirectURL = session?.organization?.redirectUrl
  const di = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddSsoMemberInputs>({ resolver: yupResolver(addSSOMemberSchema) })

  const onSubmit: SubmitHandler<AddSsoMemberInputs> = async (adminDetails) => {
    if (provider === '') {
      toast.error('Please select a provider', { duration: 6000 })
    } else {
      const ssoPayload = {
        ...adminDetails,
        provider: provider,
        slug: session.organization.slug,
      }
      const success = await di(applySsoSettings(ssoPayload))
      if (!success) {
        toast.error('Failed to apply SSO settings', { duration: 6000 })
      }
    }
  }

  return (
    <>
      <div className="flex justify-center">
        <p className="">Select your existing SSO provider to connect.</p>
      </div>
      <div className="flex gap-2 justify-center mt-4">
        <Box
          className="flex-1 cursor-pointer"
          onClick={() => {
            setProvider('okta')
          }}
        >
          <Card size="2" className={provider === 'okta' ? 'bg-PurpleBlue-7' : ''}>
            <div className="flex justify-center">
              <img src={oktaLogo} alt="Okta logo" />
            </div>
          </Card>
        </Box>
        <Box
          className="flex-1 cursor-pointer"
          onClick={() => {
            setProvider('azure')
          }}
        >
          <Card size="2" className={`h-full flex items-center ${provider === 'azure' ? 'bg-PurpleBlue-7' : ''}`}>
            <div className="flex justify-center">
              <img src={azureLogo} alt="Azure logo" className="px-1.5" />
            </div>
          </Card>
        </Box>
        <Box
          className="flex-1 cursor-pointer"
          onClick={() => {
            setProvider('google')
          }}
        >
          <Card size="2" className={`h-full flex items-center ${provider === 'google' ? 'bg-PurpleBlue-7' : ''}`}>
            <img src={googleLogo} alt="Google logo" className="px-1.5" />
          </Card>
        </Box>
      </div>
      <div className="flex justify-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-[400px] mt-4">
          <div className="block">
            <label className="text-darkGreyBlue font-semibold text-xs block">REDIRECT URL</label>
            <div className="min-h-10 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm mb-6 break-all">
              {redirectURL}
              <div className="flex mt-0.5">
                <IconButton
                  type="button"
                  size="2"
                  color="violet"
                  variant="ghost"
                  onClick={() => {
                    navigator.clipboard.writeText(redirectURL)
                    toast.success('Redirect URL copied to clipboard', { duration: 6000 })
                  }}
                >
                  <CopyIcon height="16" width="16" />
                </IconButton>
              </div>
            </div>
            <div className="w-[400px]">
              <p className="mb-2 font-semibold">Provide the following integration details.</p>
              <label className="text-darkGreyBlue font-semibold text-xs block">CLIENT ID</label>
              <Input
                type="text"
                placeholder="Client ID"
                {...register('clientId', { required: true })}
                onChange={undefined}
              />
              {errors.clientId && <p className="text-ErrorRed">{errors.clientId.message}</p>}
              <label className="text-darkGreyBlue font-semibold text-xs block mt-2">CLIENT SECRET</label>
              <Input
                type="password"
                placeholder="Client Secret (all characters)"
                {...register('clientSecret', { required: true })}
                onChange={undefined}
              />
              {errors.clientSecret && <p className="text-ErrorRed">{errors.clientSecret.message}</p>}
              <label className="text-darkGreyBlue font-semibold text-xs block mt-2">TENANT</label>
              <Input
                type="text"
                placeholder="Tenant"
                {...register('tenant', { required: true })}
                onChange={undefined}
              />
              {errors.tenant && <p className="text-ErrorRed">{errors.tenant.message}</p>}
            </div>

            <Button
              type="submit"
              className="w-[400px] h-[36px] bg-PurpleBlue-10 rounded mt-[19px] text-white font-semibold text-sm cursor-pointer"
            >
              Apply Settings
            </Button>
          </div>
        </form>
      </div>
      <div className="flex justify-center mt-8">
        <div className="block">
          <p className="text-sm mx-auto text-center">Need help?</p>
          <p className="text-sm">
            Please reference{' '}
            <a
              href="https://docs.iverify.io/single-sign-on-sso"
              target="_blank"
              className="text-PurpleBlue-10 font-semibold"
            >
              SSO Integration Setup Documentation
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default SsoSettingsPage
